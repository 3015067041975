/*
  *页面布局
  bill_yang 2021.06
*/
import { CloseCircleFilled } from '@ant-design/icons';
import { message, Modal } from 'antd';
import { Mail, PhoneForwarded, QrCode } from 'lucide-react';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { toggleCoupon } from 'redux/actions/couponAction';
import { getLogin } from 'redux/actions/getLogin';
import { getActivity, getInfo } from 'services';
import styled from 'styled-components';
import {
  clearLocalStorage,
  clearLocalStorageAll,
  clearSessionStorage,
  clearSessionStorageAll,
  getLocalStorage,
  getQueryVariable,
  getSessionStorage,
  openLoginPopup,
  saveUserInfo,
  sendPendoCode,
  setLocalStorage,
  setSessionStorage,
} from 'untils/auth';
import '../../assets/style/itemStyle/layout.scss';
import '../../assets/style/tempResponsiveFix.scss';
import Coupons from '../coupons/Coupons';
import SuccessfulState from '../coupons/SuccessfulState';
import PrivacyNotice from '../public/PrivacyNotice';
import BoundFacebook from '../sign/BoundFacebook';
import BoundGoogle from '../sign/BoundGoogle';
import BoundWx from '../sign/BoundWx';
import ForgotPassword from '../sign/ForgotPassword';
import SignIn from '../sign/SignIn';
import Signup from '../sign/Signup';
import Navbar from './Navbar';

const urlArr = [
  '/',
  '/charterQuote',
  '/emptyLeg',
  '/blog',
  '/aboutUs',
  '/contactUs',
  '/partnerWithUs',
  '/privacyPolicy',
  '/home',
];

const marqueeNotice = [
  '/',
  '/charterQuote',
  '/emptyLeg',
  '/membership',
  '/events/christmas',
  '/jetSale',
  '/blog',
  '/aboutUs',
  '/contactUs',
  '/partnerWithUs',
  '/affiliatePartner',
  // '/searchFlights',
  '/home',
];

export const HeaderNotice = styled.div`
  width: 100%;
  height: 48rem;
  background: rgba(0, 99, 219, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconNotice = styled.img`
  width: 40rem;
  height: 40rem;
`;

export const IconClose = styled.img`
  width: 24rem;
  height: 24rem;
  cursor: pointer;
`;

export const Header = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
`;

const StyledPhoneForwarded = styled(PhoneForwarded)`
  color: ${(props) =>
    window.location.pathname !== '/' || props.$isScroll ? 'black' : 'white'}; /* 初始颜色 */
  &:hover {
    color: #0063db; /* hover 时的颜色 */
  }
`;

const StyledMail = styled(Mail)`
  color: ${(props) =>
    window.location.pathname !== '/' || props.$isScroll ? 'black' : 'white'}; /* 初始颜色 */
  &:hover {
    color: #0063db; /* hover 时的颜色 */
  }
`;

const StyledQrCode = styled(QrCode)`
  color: ${(props) =>
    window.location.pathname !== '/' || props.$isScroll ? 'black' : 'white'}; /* 初始颜色 */
  &:hover {
    color: #0063db; /* hover 时的颜色 */
  }
`;

const StyledSvg = styled.svg`
  color: ${(props) =>
    window.location.pathname !== '/' || props.$isScroll ? 'black' : 'white'}; /* 初始颜色 */
  &:hover {
    color: #0063db; /* hover 时的颜色 */
  }
`;

// 主页布局
class Layout extends React.Component {
  constructor(props) {
    super(props);
    let currentTime = new Date().getTime();
    let oneDay = 24 * 60 * 60 * 1000;
    this.state = {
      isModalVisible: false, // 弹框显示隐藏
      thisIndex: 0, // 索引值
      nav: ['Sign in', 'Sign up'], // 导航
      openId: '',
      couponDetail: {}, // 活动详情
      facebookOpenId: '',
      userId: getLocalStorage('userId') ? JSON.parse(getLocalStorage('userId')) : '', // 用户信息id
      userDetails: {}, // 接口获取到用户信息
      openCode: false, //二维码弹窗
      noticeShow: true, //通告栏
      isTextPromptShow: currentTime - getLocalStorage('acceptCookies') > oneDay || false, // 底部cookie
      isScroll: this.props.location.pathname !== '/', // 非首页默认显示滚动样式
    };
  }

  //打开二维码
  openCodeHandle = () => {
    this.setState({
      openCode: true,
    });
  };

  // 忘记密码
  forgotPassword = () => {
    this.setState({
      thisIndex: 3,
    });
  };
  // 打开弹窗
  handleOk = () => {
    this.props.changeLogin('open');
    let body = document.body;
    body.style.overflow = 'hidden';
  };
  // 关闭弹框
  handleCancel = () => {
    this.props.changeLogin('close');
    let body = document.body;
    body.style.overflow = 'auto';
    clearLocalStorage('details');
    this.setState({
      thisIndex: 0,
    });
  };
  // 关闭优惠卷
  couponsHandleCancel = () => {
    this.props.toggleCoupon({
      couponsShow: false,
    });
  };
  // 下拉菜单
  jump = () => {
    this.props.history.push('/flightsList');
  };

  //clear data
  initPage = (e, url) => {
    e.preventDefault();
    clearSessionStorage('from');
    clearSessionStorage('to');
    clearSessionStorage('flyLocationTo');
    clearSessionStorage('flyLocationForm');
    clearSessionStorage('userFormData');
    clearSessionStorage('emptyLegsStartDate');
    clearSessionStorage('emptyLegsEndDate');
    clearSessionStorage('fromDetails');
    clearSessionStorage('toDetails');
    this.props.history.push(`/${url}`);
  };

  clearDate = () => {
    clearSessionStorage('from');
    clearSessionStorage('to');
    clearSessionStorage('flyLocationTo');
    clearSessionStorage('flyLocationForm');
    clearSessionStorage('userFormData');
    clearSessionStorage('emptyLegsStartDate');
    clearSessionStorage('emptyLegsEndDate');
    clearSessionStorage('fromDetails');
    clearSessionStorage('toDetails');
    clearSessionStorage('payDetails');
    clearSessionStorage('userMember');
  };

  // 跳转页面
  jumpPage = (path, e) => {
    e.stopPropagation();
    if (path === '/personalInformation2') {
      return this.props.history.push(`/personalInformation/edit`);
    }
    if (path === '/personalInformation') {
      return this.props.history.push(`/personalInformation/view`);
    }
    this.props.history.push(path);
  };
  gainCoupon = () => {
    this.props.toggleCoupon({
      couponsShow: true,
      activityState: true,
    });
  };
  // google绑定信息
  boundGoogle = (e) => {
    this.setState({
      thisIndex: 4,
      openId: e,
    });
  };
  // facebook登录
  boundFacebook = (e) => {
    this.setState({
      thisIndex: 6,
      facebookOpenId: e,
    });
  };
  couponsBoundFacebook = () => {
    this.boundFacebook();
    openLoginPopup('open');
    let body = document.body;
    body.style.overflow = 'hidden';
  };
  // 退出登录
  signOut = () => {
    Modal.confirm({
      getContainer: document.getElementById('root'),
      content: 'Are you sure you want to exit?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        let acceptCookies = getLocalStorage('acceptCookies');
        clearLocalStorageAll();
        clearSessionStorageAll();
        setLocalStorage('acceptCookies', acceptCookies);
        openLoginPopup('close');
        setSessionStorage('coupons', true);
        message.success('Logout Successful!');
        this.setState({
          activityState: false,
        });
        this.props.history.push('/');
        // this.getActivity();
      },
      onCancel: () => {
        console.log('Cancel');
      },
    });
  };
  // 优惠卷弹框 子传父
  couponsPopupFun = (val) => {
    if (!val) {
      this.handleOk();
    }
    this.couponsHandleCancel();
    this.setState({
      thisIndex: 0,
    });
  };
  // 获取活动
  getActivity = () => {
    getActivity().then((res) => {
      if (res.code && Object.keys(res.data).length > 0) {
        let { couponDetail } = this.state;
        couponDetail = res.data;

        this.setState({
          couponDetail,
        });
        if (
          !getLocalStorage('token') &&
          !getSessionStorage('coupons') &&
          !getSessionStorage('resetEamil') &&
          this.props.location.pathname === '/'
        ) {
          setTimeout(() => {
            this.props.toggleCoupon({
              couponsShow: true,
            });
          }, 2000);
          setSessionStorage('coupons', true);
        }
        setSessionStorage('isCoupons', true);
      } else {
        this.props.toggleCoupon({
          couponsShow: false,
        });
        clearSessionStorage('isCoupons');
      }
    });
  };

  // 关闭优惠券公告
  closeHeaderNotice = () => {
    this.setState({
      noticeShow: false,
    });
    setSessionStorage('notice', false);
    if (this.props.location.pathname === '/charterQuote') {
      let domStyle =
        '.gm-fullscreen-control{top: 86rem !important;}.choose-list{top: 96rem !important;}';
      let dom = document.getElementsByClassName('charter-quote')[0];
      let style = document.createElement('style');
      style.type = 'text/css';
      if (style.styleSheet) {
        style.styleSheet.cssText = domStyle;
      } else {
        style.appendChild(document.createTextNode(domStyle));
      }
      dom.appendChild(style);
    }
  };
  // 关闭底部提示框
  closeTextPrompt = () => {
    let closeTime = new Date().getTime();
    setLocalStorage('acceptCookies', closeTime);
    this.setState({
      isTextPromptShow: false,
    });
  };
  // 滚动样式
  scrollHandle = (e) => {
    const { headerScroll } = this;

    // 仅首页滚动样式改变
    if (headerScroll && this.props.location.pathname === '/') {
      const scrollTop =
        (e.srcElement ? e.srcElement.documentElement.scrollTop : false) ||
        window.pageYOffset ||
        (e.srcElement ? e.srcElement.body.scrollTop : 0);
      if (scrollTop > 0) {
        headerScroll.classList.add('header-scroll');
        this.setState({ isScroll: true });
      } else {
        headerScroll.classList.remove('header-scroll');
        this.setState({ isScroll: false });
      }
    }
  };
  UNSAFE_componentWillMount() {
    console.log('hello');
    this.getActivity();
  }

  componentDidMount() {
    let sourceId = getQueryVariable('sourceId');
    if (sourceId) setSessionStorage('sourceId', sourceId);

    // 获取个人信息
    if (getLocalStorage('token')) {
      getInfo(this.state.userId).then((res) => {
        if (res.code) {
          this.setState({
            userDetails: res.data.info,
          });
          saveUserInfo(res.data.info);
        }
      });
      if (process.env.REACT_APP_ENV === 'production') sendPendoCode(this.state.userId);
    }

    //导航栏滚动样式处理
    window.addEventListener('scroll', this.scrollHandle);

    // 监听url变化
    this.unlisten = this.props.history.listen((location) => {
      this.setState({ isScroll: location.pathname !== '/' });
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollHandle);

    // 组件卸载时移除监听器
    if (this.unlisten) {
      this.unlisten();
    }
  }

  render() {
    let {
      thisIndex,
      nav,
      openId,
      couponDetail,
      unionId,
      facebookOpenId,
      openCode,
      noticeShow,
      isTextPromptShow,
      isScroll,
    } = this.state;
    const { coupon } = this.props;
    return (
      <>
        <div className="layout" id="layout">
          {/* 顶部 */}
          <Header
            ref={(c) => (this.headerScroll = c)}
            className={`${this.props.location.pathname !== '/' && 'header-scroll'}`} // 非首页默认滚动样式
          >
            <div className="header-container">
              <div className="w1500 header-wrapper">
                <Link
                  className="header-left"
                  to={'/'}
                  onClick={() => {
                    this.clearDate();
                  }}
                >
                  <img
                    className="logo"
                    src={
                      isScroll
                        ? require('../../assets/imgs/svg/header-logo.svg').default
                        : '/imgs/svg/home/logo.svg'
                    }
                    alt="Airacer airtaxi"
                  />
                </Link>
                <Navbar name="" history={this.props.history} isScroll={isScroll} />
                <div className="header-right">
                  <ul className="header-info">
                    <li>
                      <a href="tel:+1 800-213-5051">
                        <StyledPhoneForwarded $isScroll={isScroll} />
                      </a>
                    </li>
                    <li>
                      <a href="mailto:charter@airacer.com">
                        <StyledMail $isScroll={isScroll} />
                      </a>
                    </li>
                    <li>
                      <a href="https://wa.me/13473832631">
                        <StyledSvg
                          $isScroll={isScroll}
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_2000_12710)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M20.5034 3.48755C19.3939 2.37759 18.0759 1.49802 16.6252 0.899491C15.1745 0.30096 13.6197 -0.00471772 12.0503 5.50389e-05C5.46428 5.50389e-05 0.102857 5.33469 0.100178 11.8929C0.0972648 13.9812 0.647729 16.0329 1.69553 17.8393L0 24L6.33481 22.3463C8.08832 23.2962 10.0512 23.7934 12.0455 23.7927H12.0503C18.6359 23.7927 23.9967 18.4575 24 11.8998C24.004 10.3359 23.6969 8.7867 23.0966 7.3425C22.4963 5.8983 21.6148 4.5879 20.5034 3.48755ZM12.0503 21.7854H12.0461C10.2682 21.7859 8.52272 21.3101 6.99106 20.4075L6.62838 20.1932L2.86928 21.1747L3.87267 17.527L3.63642 17.152C2.64235 15.5782 2.11567 13.7544 2.11768 11.8929C2.11768 6.44308 6.57535 2.00898 12.0541 2.00898C14.6823 2.00429 17.2047 3.04373 19.0666 4.89868C20.9285 6.75363 21.9774 9.27218 21.9825 11.9004C21.9803 17.3507 17.5248 21.7854 12.0503 21.7854ZM17.498 14.3823C17.1996 14.2334 15.7302 13.5145 15.458 13.4154C15.1859 13.3163 14.985 13.2665 14.7862 13.5643C14.5875 13.8622 14.0148 14.5286 13.8407 14.7295C13.6666 14.9304 13.4925 14.9523 13.1941 14.8034C12.8957 14.6545 11.933 14.3411 10.7925 13.3286C9.90481 12.5406 9.30588 11.5677 9.13177 11.2704C8.95766 10.9731 9.11302 10.8118 9.26248 10.664C9.39695 10.5306 9.56088 10.3168 9.71034 10.1432C9.85981 9.96967 9.90963 9.84539 10.0087 9.64718C10.1078 9.44896 10.0586 9.27539 9.98409 9.127C9.90963 8.97861 9.31231 7.51557 9.06374 6.9204C8.82106 6.34075 8.57516 6.41951 8.39195 6.4104C8.21784 6.40183 8.01695 6.39968 7.81927 6.39968C7.66815 6.40362 7.51947 6.43866 7.3825 6.50263C7.24553 6.5666 7.12322 6.65811 7.0232 6.77147C6.74945 7.06932 5.97803 7.78932 5.97803 9.25075C5.97803 10.7122 7.04945 12.1265 7.19731 12.3247C7.34517 12.5229 9.30266 15.5245 12.2978 16.8118C12.854 17.05 13.4223 17.2588 14.0003 17.4375C14.7155 17.6636 15.3664 17.632 15.8807 17.5554C16.4544 17.4702 17.6485 16.8364 17.8966 16.1422C18.1446 15.4479 18.1452 14.8532 18.0707 14.7295C17.9962 14.6057 17.7969 14.5307 17.498 14.3823Z"
                              fill="currentColor"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2000_12710">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </StyledSvg>
                      </a>
                    </li>
                    <li>
                      <div className="header-code">
                        <StyledQrCode
                          $isScroll={isScroll}
                          onMouseEnter={() => this.openCodeHandle()}
                        />

                        <div className={`code-box ${openCode ? 'active' : ''}`}>
                          <img
                            className="QRcode"
                            src="https://qizhong19920114testbucket.s3.amazonaws.com/taxi-qr.png"
                            alt="QR icon"
                          />
                          <p>Contact us on Wechat</p>
                        </div>
                      </div>
                      <div
                        className={`code-mask ${openCode ? 'active' : ''}`}
                        onMouseEnter={() => this.setState({ openCode: false })}
                      ></div>
                    </li>
                  </ul>
                  <div className="header-sign">
                    {!getLocalStorage('token') ? (
                      <button className="sign bg-image" onClick={() => this.handleOk()}>
                        <span>SIGN IN</span>
                      </button>
                    ) : (
                      <div className="portrait-name">
                        <img
                          src={`${
                            JSON.parse(getLocalStorage('user')).photo &&
                            JSON.parse(getLocalStorage('user')).photo !== '1'
                              ? JSON.parse(getLocalStorage('user')).photo
                              : require('../../assets/imgs/svg/portrait.svg').default
                          }`}
                          alt="portrait icon"
                        />
                        <button onClick={() => this.jump()}>
                          <span className="user-name">
                            {JSON.parse(getLocalStorage('user')) &&
                              JSON.parse(getLocalStorage('user')).name}
                          </span>
                        </button>

                        <div className="personal-menu">
                          <button onClick={(e) => this.jumpPage('/personalInformation', e)}>
                            <span>Profile</span>
                          </button>

                          <button onClick={(e) => this.jumpPage('/flightsList', e)}>
                            <p className="my-flights">My Flights</p>
                          </button>
                          <button onClick={(e) => this.jumpPage('/inquireList', e)}>
                            <p className="my-inquire">My Inquiries</p>
                          </button>
                          <ul
                            className={`${
                              this.props.location.pathname === '/charterQuote' ? 'ul-black' : ''
                            }`}
                          >
                            <button
                              className="bg-image"
                              onClick={(e) => this.jumpPage('/membershipCards', e)}
                            ></button>
                            <button
                              className="bg-image"
                              onClick={(e) => this.jumpPage('/coupons', e)}
                            ></button>
                            <button
                              className="bg-image"
                              onClick={(e) => this.jumpPage('/passenger/add', e)}
                            ></button>
                            <button
                              className="bg-image"
                              onClick={(e) => this.jumpPage('/changePassword', e)}
                            ></button>
                          </ul>
                          <button className="sign-out" onClick={(e) => this.signOut(e)}>
                            Sign out
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {marqueeNotice.includes(this.props.location.pathname) &&
            !getLocalStorage('token') &&
            !getSessionStorage('notice') ? (
              <HeaderNotice
                ref={(c) => (this.headerNotice = c)}
                style={{ display: noticeShow ? 'flex' : 'none' }}
              >
                <IconNotice
                  src={require('../../assets/imgs/svg/header-notice.svg').default}
                  alt="notice icon"
                />
                <button
                  className="marquee activity"
                  onClick={() => {
                    this.props.toggleCoupon({
                      couponsShow: true,
                      activityState: false,
                    });
                  }}
                >
                  <div className="wrapper">
                    <p className="marqueeWords">
                      {couponDetail?.Name || 'Your Private Jet Solution Starts Here'}
                      {!couponDetail?.Name && (
                        <span style={{ color: '#fff', marginLeft: 16 }}>
                          {'Sign Up with Airacer Today!'}
                        </span>
                      )}
                    </p>
                  </div>
                </button>
                <IconClose
                  src={require('../../assets/imgs/svg/header-close.svg').default}
                  alt="close icon"
                  onClick={this.closeHeaderNotice}
                />
              </HeaderNotice>
            ) : null}
          </Header>
          {/* 内容 */}
          <div className="main">{this.props.children}</div>

          {isTextPromptShow && urlArr.includes(this.props.location.pathname) && (
            <PrivacyNotice closeTextPrompt={this.closeTextPrompt} />
          )}

          {/* 登录弹窗 */}
          <Modal
            maskClosable={false}
            closeIcon={<CloseCircleFilled />}
            className="sign-container"
            title=""
            getContainer={() => document.getElementById('layout')}
            maskStyle={{ backdropFilter: 'blur(10px)' }}
            centered={true}
            destroyOnClose={true}
            open={this.props.getLoginShow}
            footer={null}
            onCancel={this.handleCancel}
          >
            <div className="login-form">
              {/* 导航栏 */}
              {thisIndex === 0 || thisIndex === 1 ? (
                <ul className="login-nav">
                  {nav.map((item, index) => {
                    return (
                      <li
                        className={thisIndex === index ? 'active' : null}
                        onClick={() =>
                          this.setState({
                            thisIndex: index,
                          })
                        }
                        key={index}
                      >
                        {item}
                      </li>
                    );
                  })}
                </ul>
              ) : null}
              {/* 登录 */}
              {thisIndex === 0 ? (
                <SignIn
                  history={this.props.history}
                  forgotPassword={this.forgotPassword}
                  gainCoupon={this.gainCoupon}
                  facebookLogin={this.boundFacebook}
                  boundGoogle={this.boundGoogle}
                  onCancel={this.couponsHandleCancel}
                />
              ) : // 注册
              thisIndex === 1 ? (
                <Signup
                  login={() => {
                    this.setState({
                      thisIndex: 0,
                    });
                  }}
                  history={this.props.history}
                  gainCoupon={this.gainCoupon}
                  facebookLogin={this.boundFacebook}
                  onCancel={this.couponsHandleCancel}
                />
              ) : null}
              {/* 忘记密码 */}
              {thisIndex === 3 ? (
                <ForgotPassword
                  forgetPwd={() => {
                    this.setState({
                      thisIndex: 0,
                    });
                  }}
                />
              ) : null}
              {/* google绑定密码 */}
              {thisIndex === 4 ? (
                <BoundGoogle
                  history={this.props.history}
                  changeLogin={() => this.handleCancel()}
                  openId={openId}
                  gainCoupon={this.gainCoupon}
                />
              ) : null}
              {thisIndex === 5 ? (
                <BoundWx
                  history={this.props.history}
                  id={unionId}
                  changeLogin={() => this.handleCancel()}
                  gainCoupon={this.gainCoupon}
                />
              ) : null}
              {thisIndex === 6 ? (
                <BoundFacebook
                  openId={facebookOpenId}
                  history={this.props.history}
                  changeLogin={() => this.handleCancel()}
                  gainCoupon={this.gainCoupon}
                />
              ) : null}
              {/* <BoundGoogle /> */}
              {/* <BoundWx /> */}
            </div>
          </Modal>

          {/* 优惠卷弹窗 */}
          <Modal
            maskClosable={false}
            closeIcon={<CloseCircleFilled />}
            className="sign-container"
            title=""
            getContainer={() => document.getElementById('layout')}
            maskStyle={{ backdropFilter: 'blur(10px)' }}
            centered={true}
            destroyOnClose={true}
            open={coupon.couponsShow}
            footer={null}
            onCancel={this.couponsHandleCancel}
          >
            {coupon.activityState ? (
              <SuccessfulState
                type={'layout'}
                couponsPopupFun={this.couponsPopupFun}
                onCancel={this.couponsHandleCancel}
              />
            ) : (
              <Coupons
                gainCoupon={this.gainCoupon}
                couponDetail={couponDetail}
                couponsPopupFun={this.couponsPopupFun}
                onCancel={this.couponsHandleCancel}
                facebookLogin={this.boundFacebook}
                couponsBoundFacebook={this.couponsBoundFacebook}
              />
            )}
          </Modal>
        </div>
      </>
    );
  }
}
// react-redux状态
const mapStateToProps = (state) => {
  return {
    getLoginShow: state.getLoginShow,
    coupon: state.coupon,
  };
};
// react-redux方法
const mapDispatchToProps = (dispatch) => {
  return {
    changeLogin: (type) => dispatch(getLogin(type)),
    toggleCoupon: (data) => dispatch(toggleCoupon(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));
