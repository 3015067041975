/*
  *注册组件
  bill_yang 2021.09
*/
import { Button, Form, Input, message } from 'antd';

import VerifyPassword from 'components/verifyPassword/VerifyPassword';
import md5 from 'js-md5';
import React, { Component } from 'react';
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';
import store from 'redux/store';
import { facebookLogin, googleLogin, login, register } from 'services/index';
import {
  getLocalStorage,
  getSessionStorage,
  openLoginPopup,
  regularCheck,
  saveUserInfo,
  sendPendoCode,
  setLocalStorage,
} from 'untils/auth';
import country from 'untils/country.json';
import { tapCustomer } from 'untils/tracking';

export default class Signup extends Component {
  formRef = React.createRef();
  state = {
    checkedChoose: false, // 勾选框
    isShow: false, // 选择国家区号下拉框
    country: 'United States', // 选择国家
    list: country, // 国家区号
    emailTipsValue: 'Email Address', //email框提示文案
    show: false, // 密码提示规则框
    pwdVal: '', // 密码框值
    isAccept: true, // 是否订阅
  };
  onSearch = () => {};
  // 获取勾选框
  getValue = () => {
    let { checkedChoose } = this.state;
    this.setState({
      checkedChoose: !checkedChoose,
    });
  };

  getAcceptValue = () => {
    let { isAccept } = this.state;
    this.setState({
      isAccept: !isAccept,
    });
  };

  //email聚焦时更改提示文案
  emailInputFocus = () => {
    this.setState({
      emailTipsValue: 'Email is the only logged in',
    });
  };
  //email框失去焦点时更改提示文案
  emailInputBlur = () => {
    this.setState({
      emailTipsValue: 'Email Address',
    });
  };
  // 提交
  onFinish = (e) => {
    let { checkedChoose } = this.state;

    if (!checkedChoose) {
      return message.warning('Please check Terms and Conditions.');
    }

    let params = {};
    if (this.props.type === 'coupons') {
      params = {
        account: e.account,
        rcvEmailFlag: 1,
      };
    } else {
      params = {
        fname: e.fname,
        account: e.account,
        password: md5(e.password),
        rcvEmailFlag: 1,
      };
    }

    if (this.props.type === 'coupons') {
      setLocalStorage('accountRegister', JSON.stringify(params));
      // 弹出领卷成功
      this.props.gainCoupon();
    } else {
      const channelObj = JSON.parse(localStorage.getItem('channelObj'));
      params.channel = channelObj?.channel || '';
      register(params).then((res) => {
        if (res.code) {
          let data = {
            account: params.account,
            password: params.password,
          };
          login(data).then((res) => {
            if (res.code) {
              tapCustomer({ userId: res.data.userid });

              if (process.env.REACT_APP_ENV === 'production') sendPendoCode(res.data.userid);
              // 储存个人信息
              saveUserInfo(res.data);
              // 弹出领卷成功
              this.props.gainCoupon();

              // 判断是否google 登录
              if (res.data.type !== undefined) {
                // setLocalStorage('loginType', true);
              }

              store.dispatch({
                type: 'token',
                data: res.data.accessToken,
              });
              setLocalStorage('time', res.data.accessExpire + '000');
              setLocalStorage('userId', res.data.userid);
              // 提示成功
              message.success('Login Successful!');
              let body = document.body;
              body.style.overflow = 'auto';
              openLoginPopup('close');
              // 详情页
              if (getLocalStorage('details') !== null) {
                this.props.history.push('/payDetails');
              }

              if (getLocalStorage('scheduled') !== null) {
                let id = JSON.parse(getSessionStorage('fromDetails')).flightId;
                this.props.history.push(`/searchFlightsPassengers/${id}`);
              }

              // 去会员填写信息页
              const urlParams = new URL(window.location.href);
              const pathname = urlParams?.pathname;
              if (pathname === '/addCardInformation') {
                this.props.history.push('/membership');
              }

              if (getSessionStorage('chaData')) {
                store.dispatch({
                  type: 'charterQuoteSumbit',
                });
              }

              if (getSessionStorage('eptData')) {
                store.dispatch({
                  type: 'emptyLegSumbit',
                });
              }
            } else {
              if (res.msg.indexOf('incorrect password') !== -1) {
                message.error(
                  'The email and password you entered do not match. Please try again or click "Forgot Password".'
                );
              } else if (res.msg.indexOf("The account doesn't exist") !== -1) {
                message.error("The account doesn't exist. Please Sign Up.");
              } else {
                message.error(res.msg);
              }
            }
          });
        } else {
          message.error(res.msg);
        }
      });
    }
  };
  // 选择国家区号
  chooseCode = (item) => {
    this.formRef.current.setFieldsValue({
      country: item.phoneCode,
    });
    this.setState({
      isShow: false,
      country: item.countryName,
      list: country,
    });
  };
  // 模糊搜索国家区号
  filterCode = (e) => {
    let list = country.filter((item) => {
      return (
        item.countryName.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 ||
        item.phoneCode.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
      );
    });
    this.setState({
      list,
    });
  };
  // 谷歌登录失败
  onFailure = (e) => {
    // message.error('Login failed!');
    console.log(e);
  };
  // 谷歌登录成功
  onSuccess = (e) => {
    googleLogin(e.tokenId).then((res) => {
      if (res.code) {
        let body = document.body;
        body.style.overflow = 'auto';
        // 提示成功
        message.success('Login Successful!');
        // 返回页面

        if (!res.data.userid) {
          this.props.boundGoogle(res.data.openId);
          // this.props.history.push(`/bound/${res.data.openId}`);
        } else {
          if (process.env.REACT_APP_ENV === 'production') sendPendoCode(res.data.userid);
          let user = {
            account: res.data.email,
            name: res.data.name,
            surName: res.data.surName,
            phone: res.data.phone,
            phoneCode: res.data.phoneCode,
            countryIso2: res.data.countryIso2,
            country: res.data.country,
            photo: res.data.photo,
            membershipCardType: res.data.membershipCardType,
            membershipShuttleNumber: res.data.membershipShuttleNumber,
          };
          saveUserInfo(user);
          store.dispatch({
            type: 'token',
            data: res.data.accessToken,
          });
          setLocalStorage('userId', res.data.userid);
          setLocalStorage('time', res.data.accessExpire + '000');
          // setLocalStorage('loginType', true);
          openLoginPopup('close');
          // 弹出领卷成功
          if (res.data.newUser) {
            this.props.gainCoupon();
          } else {
            // 关闭新人活动的注册弹窗
            if (this.props.onCancel) {
              this.props.onCancel();
            }
          }

          // 详情页
          if (getLocalStorage('details') !== null) {
            this.props.history.push('/payDetails');
          }

          if (getLocalStorage('scheduled') !== null) {
            let id = JSON.parse(getSessionStorage('fromDetails')).flightId;
            this.props.history.push(`/searchFlightsPassengers/${id}`);
          }

          // 去会员填写信息页
          const urlParams = new URL(window.location.href);
          const pathname = urlParams?.pathname;
          if (pathname === '/addCardInformation') {
            this.props.history.push('/membership');
          }

          if (getSessionStorage('chaData')) {
            store.dispatch({
              type: 'charterQuoteSumbit',
            });
          }

          if (getSessionStorage('eptData')) {
            store.dispatch({
              type: 'emptyLegSumbit',
            });
          }
        }
      } else {
        message.error(res.msg);
      }
    });
  };
  // facebook登录
  responseFacebook = (e) => {
    if (e.status === 'unknown') {
      return;
    }
    facebookLogin(e.accessToken).then((res) => {
      if (res.code) {
        let body = document.body;
        body.style.overflow = 'auto';

        // 返回页面
        if (!res.data.userid) {
          this.props.facebookLogin(res.data.openId);

          if (this.props.type === 'coupons') {
            this.props.couponsBoundFacebook();
          }
          // this.props.history.push(`/bound/${res.data.openId}`);
        } else {
          if (process.env.REACT_APP_ENV === 'production') sendPendoCode(res.data.userid);
          // 提示成功
          message.success('Login Successful!');
          let user = {
            account: res.data.email,
            name: res.data.name,
            surName: res.data.surName,
            phone: res.data.phone,
            phoneCode: res.data.phoneCode,
            countryIso2: res.data.countryIso2,
            country: res.data.country,
            photo: res.data.photo,
            membershipCardType: res.data.membershipCardType,
            membershipShuttleNumber: res.data.membershipShuttleNumber,
          };
          saveUserInfo(user);
          store.dispatch({
            type: 'token',
            data: res.data.accessToken,
          });
          setLocalStorage('userId', res.data.userid);
          setLocalStorage('time', res.data.accessExpire + '000');
          // setLocalStorage('loginType', true);
          openLoginPopup('close');
          // 弹出领卷成功
          if (res.data.newUser) {
            this.props.gainCoupon();
          } else {
            // 关闭新人活动的注册弹窗
            if (this.props.onCancel) {
              this.props.onCancel();
            }
          }
          // 详情页
          if (getLocalStorage('details') !== null) {
            this.props.history.push('/payDetails');
          }

          if (getLocalStorage('scheduled') !== null) {
            let id = JSON.parse(getSessionStorage('fromDetails')).flightId;
            this.props.history.push(`/searchFlightsPassengers/${id}`);
          }

          // 去会员填写信息页
          const urlParams = new URL(window.location.href);
          const pathname = urlParams?.pathname;
          if (pathname === '/addCardInformation') {
            this.props.history.push('/membership');
          }

          if (getSessionStorage('chaData')) {
            store.dispatch({
              type: 'charterQuoteSumbit',
            });
          }

          if (getSessionStorage('eptData')) {
            store.dispatch({
              type: 'emptyLegSumbit',
            });
          }
        }
      } else {
        message.error(res.msg);
      }
    });
  };
  // 密码提示规则框
  pwdChange = (e) => {
    if (regularCheck('password', e.target.value)) {
      this.setState({
        show: false,
      });
    } else {
      this.setState({
        show: true,
      });
    }
    this.setState({
      pwdVal: e.target.value,
    });
  };

  componentDidMount() {
    this.formRef.current.setFieldsValue({
      country: 1,
    });
  }

  render() {
    let { checkedChoose, emailTipsValue } = this.state;
    let { type } = this.props;
    return (
      <div className="public-form signin">
        {type !== 'coupons' && (
          <p className="form-title">Welcome! Enter the information to create account.</p>
        )}

        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          ref={this.formRef}
          onFinish={this.onFinish}
          autoComplete="off"
        >
          {type === 'coupons' ? null : (
            <Form.Item
              name="fname"
              rules={[
                {
                  required: true,
                  message: '',
                },
                {
                  validator: (rule, value) => {
                    if (regularCheck('userName', value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(new Error('Please enter your name in English'));
                    }
                  },
                },
              ]}
            >
              <Input
                prefix={
                  <img
                    src={`${
                      type === 'coupons'
                        ? require('assets/imgs/svg/login-name.svg').default
                        : require('assets/imgs/svg/icon_name.svg').default
                    }`}
                    alt="name icon"
                  />
                }
                placeholder={`${type === 'coupons' ? 'Name' : 'Your Name'}`}
                allowClear
              />
            </Form.Item>
          )}
          {type === 'coupons' ? (
            <>
              <p style={{ color: 'black', fontSize: 18, textAlign: 'center' }}>
                Get Updates on Bespoke Flight Deals!
              </p>
              <br />
            </>
          ) : null}

          <Form.Item
            name="account"
            rules={[
              {
                required: true,
                message: '',
              },
              {
                validator: (rule, value) => {
                  if (regularCheck('email', value)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(new Error('Please enter a valid email address'));
                  }
                },
              },
            ]}
          >
            <Input
              prefix={
                <img
                  src={`${
                    type === 'coupons'
                      ? require('assets/imgs/svg/login-email.svg').default
                      : require('assets/imgs/svg/icon_email.svg').default
                  }`}
                  alt="email icon"
                />
              }
              placeholder={emailTipsValue}
              onFocus={() => {
                this.emailInputFocus();
              }}
              onBlur={() => {
                this.emailInputBlur();
              }}
              allowClear
            />
          </Form.Item>

          <div style={{ position: 'relative' }}>
            {type === 'coupons' ? null : (
              <Form.Item
                name="password"
                onChange={(e) => {
                  this.pwdChange(e);
                }}
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                  {
                    validator: (rule, value) => {
                      if (regularCheck('password', value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject();
                      }
                    },
                  },
                ]}
              >
                <Input.Password
                  prefix={
                    <img
                      src={`${
                        type === 'coupons'
                          ? require('assets/imgs/svg/login-password.svg').default
                          : require('assets/imgs/svg/icon_password.svg').default
                      }`}
                      alt="password icon"
                    />
                  }
                  onBlur={() => {
                    this.setState({ show: false });
                  }}
                  placeholder="Password"
                />
              </Form.Item>
            )}
            {this.state.show && (
              <VerifyPassword passwordValue={this.state.pwdVal} positionTop={60} />
            )}
          </div>

          <div className="check-terms">
            <button type="button" onClick={this.getValue}>
              <i className={`bg-image ${checkedChoose ? 'active' : null}`} />
            </button>
            <p>
              {type === 'coupons'
                ? 'By subscribing, you agree to subscribe to the Airacer Newsletter, and agree to the'
                : 'By continuing,you agree to our company’s'}{' '}
              <a href="/termOfUse" target="_blank">
                Terms & Conditions
              </a>{' '}
              and{' '}
              <a href="/privacyPolicy" target="_blank">
                Privacy Policy
              </a>
            </p>
          </div>
          {type === 'coupons' ? null : (
            <div className="check-terms">
              {/* <i className={`bg-image ${isAccept ? 'active' : null}`} onClick={this.getAcceptValue} /> */}
              {/* <p>Yes, I would like to sign up for the Airacer Newsletter. (Optional)</p> */}
              <p className="text">By registering, you agree to sign up to our newsletter</p>
            </div>
          )}
          <Form.Item>
            <Button loading={store.getState().getLoading} type="primary" htmlType="submit">
              {type === 'coupons' ? 'Get Started' : 'Sign up'}
            </Button>
          </Form.Item>
        </Form>
        {type === 'coupons' && (
          <div className="link-login">
            Already registered? <button onClick={this.props.openLoginPopupHandle}>Sign in</button>
          </div>
        )}
        <div className="or">or</div>
        <div className="other-login">
          <p>
            <GoogleLogin
              clientId="437987232398-s5egldv4ao6rpvv8f61391rsluk1q928.apps.googleusercontent.com"
              buttonText="Login"
              render={(renderProps) => (
                <button onClick={renderProps.onClick} disabled={renderProps.disabled}>
                  Sign in with Google
                </button>
              )}
              onSuccess={(e) => this.onSuccess(e)}
              onFailure={(e) => this.onFailure(e)}
              cookiePolicy={'single_host_origin'}
            />
            <i className="bg-image google"></i>
            <span>Continue with Google</span>
          </p>
          {/*<p>*/}
          {/*  <i className="bg-image facebook"></i>*/}
          {/*  <span>Continue with Facebook</span>*/}
          {/*  <FacebookLogin*/}
          {/*    appId="632352834436211"*/}
          {/*    fields="name,email,picture"*/}
          {/*    onClick={this.componentClicked}*/}
          {/*    autoLoad={false}*/}
          {/*    callback={this.responseFacebook}*/}
          {/*  />*/}
          {/*</p>*/}
        </div>
      </div>
    );
  }
}
