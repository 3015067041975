/*
  *优惠卷弹窗
  bill_yang 2021.10
*/
import { message } from 'antd';
import VerifyPassword from 'components/verifyPassword/VerifyPassword';
import md5 from 'js-md5';
import React, { Component } from 'react';
import store from 'redux/store';
import { tapCustomer } from 'untils/tracking';
import '../../assets/style/itemStyle/coupons.scss';
import { getUser, login, register } from '../../services/index';
import {
  clearLocalStorage,
  getLocalStorage,
  regularCheck,
  saveUserInfo,
  sendPendoCode,
  setLocalStorage,
} from '../../untils/auth';
import SelectMenu from '../selectMenu/index';

export default class SuccessfulState extends Component {
  constructor() {
    super();
    this.state = {
      sourceValue: '',
      sourceValueRequired: true,
      show: false,
      username: '', // 用户名
      pwdVal: '', // 用户密码
      ruleFirstName: true,
      rulePassword: true,
      iconShow: true,
      regParam: null,
    };
  }
  // 获取下拉菜单数据
  getSourceValue = (value) => {
    this.setState({
      sourceValue: value,
      sourceValueRequired: true,
    });
  };

  // 密码提示规则框
  pwdChange = (e) => {
    if (regularCheck('password', e.target.value)) {
      this.setState({
        show: false,
      });
    } else {
      this.setState({
        show: true,
      });
    }
    this.setState({
      pwdVal: e.target.value,
    });
  };

  // 密码的显示隐藏
  showPassword = (id) => {
    let pass = document.getElementById(id);
    if (pass.type === 'password') {
      pass.type = 'text';
    } else {
      pass.type = 'password';
    }
    this.setState({
      iconShow: !this.state.iconShow,
    });
  };

  //失焦
  blur = (value, type) => {
    this.setState({
      show: false,
    });
    if (!value) return;

    if (type === 'name') {
      if (regularCheck('userName', value)) {
        this.setState({
          ruleFirstName: true,
        });
      } else {
        this.setState({
          ruleFirstName: false,
        });
      }
    }
    if (type === 'password') {
      if (regularCheck('password', value)) {
        this.setState({
          rulePassword: true,
        });
      } else {
        this.setState({
          rulePassword: false,
        });
      }
    }
  };

  submitData = () => {
    console.log('hello');
    let { regParam, username, pwdVal, sourceValue } = this.state;
    let ruleFirstName = regularCheck('registerName', username) ? true : false;
    let rulePassword = regularCheck('password', pwdVal) ? true : false;
    let sourceValueRequired = sourceValue === '' || sourceValue === null ? false : true;

    if (regParam) {
      if (sourceValueRequired && ruleFirstName && rulePassword) {
        const channelObj = JSON.parse(localStorage.getItem('channelObj'));
        let data = {
          fname: this.state.username,
          account: regParam.account,
          rcvEmailFlag: regParam.rcvEmailFlag,
          password: md5(this.state.pwdVal),
          source: this.state.sourceValue,
          channel: channelObj?.channel || '',
        };
        register(data).then((res) => {
          if (res.code) {
            login({
              account: data.account,
              password: data.password,
            }).then((res) => {
              if (res.code) {
                tapCustomer({ userId: res.data.userid });

                if (process.env.REACT_APP_ENV === 'production') sendPendoCode(res.data.userid);
                // 储存个人信息
                saveUserInfo(res.data);

                store.dispatch({
                  type: 'token',
                  data: res.data.accessToken,
                });
                setLocalStorage('time', res.data.accessExpire + '000');
                setLocalStorage('userId', res.data.userid);
                // 提示成功
                message.success('Login Successful!');
                let body = document.body;
                body.style.overflow = 'auto';
              } else {
                if (res.msg.indexOf('incorrect password') !== -1) {
                  message.error(
                    'The email and password you entered do not match. Please try again or click "Forgot Password".'
                  );
                } else if (res.msg.indexOf("The account doesn't exist") !== -1) {
                  message.error("The account doesn't exist. Please Sign Up.");
                } else {
                  message.error(res.msg);
                }
              }
            });
          } else {
            message.error(res.msg);
          }
        });

        if (this.props.type === 'layout') {
          this.props.couponsPopupFun(true);
        } else {
          this.props.couponsHandleCancel();
        }
      }
    } else {
      let data = {
        id: JSON.parse(getLocalStorage('userId')),
        source: this.state.sourceValue,
      };
      getUser(data).then(() => {
        this.props.couponsPopupFun(true);
      });
    }

    this.setState({
      ruleFirstName,
      rulePassword,
      sourceValueRequired,
    });
  };

  componentDidMount() {
    this.setState({
      regParam: JSON.parse(getLocalStorage('accountRegister')),
    });
    clearLocalStorage('accountRegister');
  }
  render() {
    let { ruleFirstName, rulePassword, iconShow, regParam, username, pwdVal } = this.state;
    return (
      <div
        className={`activity-popup successful-coupons ${
          this.props.type === 'foot-register' ? 'foot-register' : ''
        }`}
      >
        <div className="activity-content">
          <div className="coupons-wrapper">
            <div className="successful-text">
              <h3>
                Thank you! <br />
                You’re all signed up.
              </h3>
              <p>More discounts from Airacer are coming your way.</p>
              {regParam ? (
                <div className="form">
                  <div className="name">
                    <input
                      type="text"
                      placeholder="Your name"
                      className={!ruleFirstName ? 'error' : ''}
                      onChange={(e) => {
                        this.setState({
                          username: e.target.value,
                        });
                      }}
                      value={username}
                      onBlur={(e) => {
                        this.blur(e.target.value, 'name');
                      }}
                    />
                    <img
                      src={require('assets/imgs/svg/icon_name.svg').default}
                      className="icon-img"
                      alt="name icon"
                    />
                    {!ruleFirstName && (
                      <div className="error-text">
                        <span>Please enter your name in English</span>
                      </div>
                    )}
                  </div>
                  <div className="password" style={{ position: 'relative' }}>
                    <input
                      type="password"
                      placeholder="Password"
                      className={!rulePassword ? 'error' : ''}
                      id="password"
                      onChange={(e) => {
                        this.pwdChange(e);
                      }}
                      value={pwdVal}
                      onBlur={(e) => {
                        this.blur(e.target.value, 'password');
                      }}
                    />
                    <img
                      src={require('assets/imgs/svg/icon_password.svg').default}
                      className="icon-img"
                      alt="password icon"
                    />
                    <button className="icon-pw" onClick={() => this.showPassword('password')}>
                      {iconShow ? (
                        <img src={require('assets/imgs/svg/show_pw.svg').default} alt="icon" />
                      ) : (
                        <img src={require('assets/imgs/svg/hidden_pw.svg').default} alt="icon" />
                      )}
                    </button>

                    {this.state.show && (
                      <VerifyPassword passwordValue={this.state.pwdVal} positionTop={60} />
                    )}
                  </div>
                </div>
              ) : null}
              <div className={`select ${!this.state.sourceValueRequired ? 'error' : ''}`}>
                <SelectMenu getSourceValue={this.getSourceValue} marginBottom={80} marginTop={80} />
                {!this.state.sourceValueRequired && (
                  <p className="error_text">Please select content</p>
                )}
              </div>
              <button className="submit_btn_suc" onClick={this.submitData}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
