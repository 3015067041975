/*
  *个人中心页面布局
  bill_yang 2021.09
*/
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '../../assets/style/itemStyle/personal.scss';
import { getLocalStorage } from '../../untils/auth';

class Personal extends Component {
  state = {
    nav: [
      {
        name: 'My Flights',
        path: '/flightsList',
      },
      {
        name: 'My Inquiries',
        path: '/inquireList',
      },
      {
        name: 'Membership',
        path: '/membershipCards',
      },
      {
        name: 'Coupons',
        path: '/coupons',
      },
      {
        name: 'Passengers',
        path: '/passenger/add',
      },
      {
        name: 'Change Password',
        path: '/changePassword',
      },
    ],
    user: JSON.parse(getLocalStorage('user')),
  };

  jump = (path) => {
    this.props.history.push(path);
  };
  render() {
    let { nav } = this.state;
    return (
      <div className="personal">
        <div className="personal-content">
          <div className="personal-information">
            <div className="personal-back">
              <div className="user-picture bg-image">
                <img
                  src={`${
                    JSON.parse(getLocalStorage('user')).photo &&
                    JSON.parse(getLocalStorage('user')).photo !== '1'
                      ? JSON.parse(getLocalStorage('user')).photo
                      : require('../../assets/imgs/svg/user.svg').default
                  }`}
                  alt="user icon"
                />
              </div>
              <div
                className="edit-icon"
                onClick={() => this.jump('/personalInformation/view')}
              ></div>
              <div className="user-text">
                {JSON.parse(getLocalStorage('user')).name ? (
                  <div
                    className={`${
                      JSON.parse(getLocalStorage('user')).name.length > 10
                        ? 'long-user-name'
                        : 'user-name'
                    }`}
                  >
                    <div>Hello !</div>
                    <div>
                      {JSON.parse(getLocalStorage('user')) &&
                        JSON.parse(getLocalStorage('user')).name}
                    </div>
                  </div>
                ) : (
                  <div className="user-name">
                    <div>Hello !</div>
                    <div>
                      {JSON.parse(getLocalStorage('user')) &&
                        JSON.parse(getLocalStorage('user')).name}
                    </div>
                  </div>
                )}
                {/* <p
                  onClick={() => this.jump('/personalInformation/view')}
                  className={`${
                    this.props.location.pathname === '/personalInformation/view'
                      ? 'active'
                      : null
                  }`}
                >
                  <span>Profile</span>
                </p> */}
              </div>
            </div>

            <div className="personal-nav">
              <ul>
                {nav.map((item, index) => {
                  return (
                    <li key={index} onClick={() => this.jump(item.path)}>
                      <div
                        className={`${
                          this.props.location.pathname === item.path ? 'active' : null
                        }`}
                      >
                        {item.name}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="personal-main">{this.props.children}</div>
        </div>
      </div>
    );
  }
}
export default withRouter(Personal);
